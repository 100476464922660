// DEPRECATED: Don't use this for new development. It's not feasible to build one component that can reliably handle
// in-form editing and validation for every possible variety of input component. Instead, the components in
// components/Common/Fields are enhanced with a static .Field property that is an enhanced version of the component
// that can be rendered with reduxForm's <Field /> component.
//
// Example: <Field name="category" component={Select.Field} options={categories} />
import React from 'react'
import { Field } from 'redux-form'
import ValidatedField, { CustomFieldProps, Placement } from './ValidatedField'

/*
  redux-form v6 modified the way it injects props to user interactive components like input/textarea/select.
  Custom components we've written as well as third-party ones we've loaded are already set-up to receive
  the key interaction props (such as value, onChange, onBlur, etc) as props on the control component.

  The latest version of redux-form wraps the interaction props inside a props.input container, which prevents
  our pre-existing components from binding them properly. This component flattens the input props back onto
  the top of the specified component's props so that these custom components continue to work without much
  overhead.
*/

interface ControlledFieldProps {
  name: string
  value?: any
  options?: any
  optionRenderer?: any
  optionsRenderer?: any
  valueRenderer?: any
  placement?: Placement
  component: any
  normalize?: any
  label?: string
  disabled?: boolean
  noResultsText?: any
  placeholder?: string
  isLoading?: boolean
  onChange?: (value: any) => void
  style?: any
  id?: any
  searchable?: boolean
  showAll?: boolean
  autoFocus?: boolean
  clearable?: any
  componentClass?: any
  isValidNewOption?: any
  promptTextCreator?: any
  newOptionCreator?: any
  simpleValue?: boolean
  onNeedNewData?: any
  organizationId?: any
  send_reminder?: any
}

export default class ControlledField extends React.PureComponent<ControlledFieldProps> {
  constructor(props: ControlledFieldProps) {
    super(props)
    this.renderComponent = this.renderComponent.bind(this)
  }

  renderComponent(field: CustomFieldProps) {
    const {
      name,
      component,
      placement,
      /* eslint-disable no-unused-vars */
      normalize,
      /* eslint-enable no-unused-vars */
      ...rest
    } = this.props
    const child = React.createElement(
      component,
      Object.assign(
        { disabled: field.meta.submitting },
        field.input,
        field.meta,
        rest
      )
    )
    return (
      <ValidatedField name={name} field={field} placement={placement}>
        {child}
      </ValidatedField>
    )
  }

  render() {
    return <Field {...this.props} component={this.renderComponent} />
  }
}
