import React from 'react'
import { formValues } from 'redux-form'
import isEqual from 'lodash/isEqual'

interface FormValueProps {
  value?: any
  onUpdate?: (val1: any, val2: any) => void
  children?: any
  name?: string
}

export class FormValue extends React.Component<FormValueProps> {
  componentDidUpdate(prevProps: { value: string }) {
    const { value, onUpdate } = this.props
    if (onUpdate && !isEqual(prevProps.value, value)) {
      onUpdate(value, prevProps.value)
    }
  }

  render() {
    const { children, value } = this.props
    return children({ value })
  }
}

const Enhanced = formValues<FormValueProps, any>((props: { name: string }) => ({
  value: props.name,
}))(FormValue)

export default Enhanced
