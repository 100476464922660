import React, { ReactNode } from 'react'

interface SubmitButtonProps {
  submitting?: boolean
  disabled?: boolean
  value?: ReactNode | string
  icon?: string
  children?: ReactNode
  id?: string
  onClick?: () => void
}

export default function SubmitButton(props: SubmitButtonProps) {
  const { submitting, disabled, value = 'Save', icon = 'save', children, ...rest } = props
  return (
    <button type="submit" className="btn btn-primary" disabled={submitting || disabled} {...rest}>
      <i className={`fa fa-${icon}`} /> {value || children}{' '}
      {submitting && <i className="fa fa-cog fa-spin" />}
    </button>
  )
}
