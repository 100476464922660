import { compose, renameProps, flattenProp } from 'recompose'
import { Checkbox as BootstrapCheckbox, CheckboxProps } from 'react-bootstrap'
import { withFeedback } from './FieldFeedback'
import { ReactElement } from 'react'

// Create a new component that enhances the Checkbox component
const CheckboxField = compose<CheckboxProps, any>(
  flattenProp('input'),
  renameProps({ value: 'checked' }),
  withFeedback
)(BootstrapCheckbox as any)

// Assign the enhanced component to Checkbox.Field
;(BootstrapCheckbox as any).Field = CheckboxField

//TODO: need to check this component functionality is working as is like before after changing it to tsx file
export default BootstrapCheckbox as unknown as CheckboxProps & { Field: ReactElement }
