import React, { ReactNode } from 'react'
import Tooltip from './Tooltip'

export default function LegacyButton(props: { children?: ReactNode; to: any }) {
  const { children, to, ...rest } = props

  return (
    <Tooltip
      id="legacy-button"
      title="Changes in the legacy system could take up to 5 minutes to synchronize back to OpsPrime."
    >
      <a
        className="btn btn-sm btn-warning"
        href={`https://bono.strenuus.com/Ops/DataQA/${to}`}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children || (
          <span>
            <i className="fa fa-arrow-circle-o-left" /> Edit
          </span>
        )}
      </a>
    </Tooltip>
  )
}
