import React, { FunctionComponent } from 'react'
import { Field } from 'redux-form'
import { withHandlers } from 'recompose'
import ValidatedField, { CustomFieldProps, Placement } from './ValidatedField'

interface RenderComponentProps {
  name: string
  placement?: Placement
  component: any
  normalize?: any
  type?: any
  value?: any
  checked?: boolean
  className?: string
  autoFocus?: boolean
  disabled?: boolean
  rows?: number | string
  maxLength?: number
  placeholder?: string
}

export const renderComponent =
  ({
    name,
    component: Component,
    type,
    value,
    placement,
    checked,
    ...rest
  }: RenderComponentProps) =>
  (field: CustomFieldProps) => {
    const childProps = {
      name,
      disabled: field.meta.submitting,
      type,
      checked,
    }
    if (Component === 'input' && (type === 'radio' || type === 'checkbox')) {
      childProps.checked = value === field.input.value
      field.input.value = value
    }
    return (
      <ValidatedField name={name} field={field} placement={placement}>
        <Component {...childProps} {...field.input} {...rest} />
      </ValidatedField>
    )
  }
/*
  Wrapper around redux-form Field for components that are input, select, or textarea with validation included.
*/
export function InputField({
  name,
  normalize,
  renderComponent,
  validate,
}: {
  name?: string
  normalize?: any
  renderComponent: FunctionComponent<any>
  validate?: any
}) {
  return (
    <Field
      name={name}
      normalize={normalize}
      component={renderComponent}
      validate={validate}
    />
  )
}
export default withHandlers({ renderComponent })(InputField)
