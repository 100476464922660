import asyncComponent from '../../AsyncComponent'

export const ProjectMappings = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappings')
)

export const ProjectMapping = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMapping')
)

export const ProjectMappingProperties = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingProperties')
)
export const ProjectMappingPropertiesBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingPropertiesBreadcrumb')
)

export const ProjectMappingClientProducts = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingClientProducts')
)

export const ProjectMappingGenerations = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingGenerations')
)

export const ProjectMappingGenerationActivities = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingGenerationActivities')
)

export const ProjectMappingGeneration = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingGeneration')
)

export const ProjectMappingGenerationsCompare = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingGenerationsCompare')
)

export const ProjectMappingApiKeys = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingApiKeys')
)

export const ProjectMappingApiWhitelisting = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectMappings" */ './ProjectMappingApiWhitelisting')
)
