import asyncComponent from '../../AsyncComponent'

export const ProductDetailPage = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './ProductDetailPage') as any
)

export const ProductChangesPage = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './ProductChangesPage') as any
)

export const PropertiesTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './PropertiesTab') as any
)

export const CarriersTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './CarriersTab') as any
)

export const MappingsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './MappingsTab') as any
)

export const EmployersTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './EmployersTab') as any
)

export const ContactsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './ContactsTab') as any
)

export const FlagsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './FlagsTab') as any
)

export const DocumentsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './DocumentsTab') as any
)

export const InternalDocumentsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './InternalDocumentsTab') as any
)

export const HistoryTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './HistoryTab') as any
)
