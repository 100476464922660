import asyncComponent from '../../AsyncComponent'

export const QAReportsPage = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './QAReportsPage') as any
)

export const EnrollmentTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './EnrollmentTab') as any
)

export const FlagsTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './FlagsTab') as any
)

export const TieringTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './TieringTab') as any
)

export const GroupModelTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './GroupModelTab') as any
)
