import React, { ReactNode } from 'react'
import _ from 'lodash'
import { Alert } from 'react-bootstrap'

interface ErrorSummaryProps {
  showLabels: boolean
  errors: any
  onDismiss?: () => void
  children: ReactNode
}

export default function ErrorSummary({
  showLabels = false,
  errors = null,
  onDismiss,
  children = null,
}: ErrorSummaryProps) {
  if (_.isEmpty(errors)) return null

  const items = Object.keys(errors).map((key) => {
    const error = Array.isArray(errors[key]) ? errors[key].join(' ') : errors[key]

    const text = showLabels
      ? `${(_ as any).titleize(key.replace('_', ' '))} ${
          error.charAt(0).toLowerCase() + error.slice(1)
        }`
      : error

    return <li key={key}>{text}</li>
  })

  return (
    <Alert bsStyle="danger" onDismiss={onDismiss}>
      {children || <h5>Errors</h5>}
      <ul>{items}</ul>
    </Alert>
  )
}
