import asyncComponent from '../../AsyncComponent'

export const DataCollectionEffortCreate = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './DataCollectionEffortCreate')
)

export const DataCollectionEffortDetailsForm = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './DataCollectionEffortDetailsForm')
)

export const DataCollectionEffortSummaryForm = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './DataCollectionEffortSummaryForm')
)

export const DataCollectionEffortFilesList = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './DataCollectionEffortFilesList')
)

export const SupplierContactList = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './SupplierContactList')
)

export const GenerationSummaryContainer = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './GenerationSummaryContainer')
)

export const Attachments = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './Attachments') as any
)

export const TransactionList = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './TransactionList') as any
)

export const GenerationDashboardContainer = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './GenerationDashboardContainer')
)

export const GenerationDashboardManualList = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './GenerationDashboardManualList')
)

export const GenerationDashboardActiveList = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './GenerationDashboardActiveList')
)

export const GenerationDashboardCompletedList = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './GenerationDashboardCompletedList')
)

export const DataCollectionEffortList = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './DataCollectionEffortList')
)

export const GenerationDetail = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './GenerationDetail')
)

export const EffortGenerationBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './EffortGenerationBreadcrumb')
)

export const EffortBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './EffortBreadcrumb')
)
