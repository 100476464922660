import _ from 'lodash'
import React from 'react'
import { compose, flattenProp, withProps } from 'recompose'
import { connect } from 'react-redux'
import { withFeedback } from '../Fields/FieldFeedback'
import SelectDropDown from './SelectDropDown'

interface ResourceDropDownProps {
  resource: any
  optionsRenderer?: (value: any) => void
  label?: ((value: any) => void) | string
  valueKey: string
  value?: any
  onChange: (value: any) => void
  onRefresh?: () => void
  showPlaceholder?: boolean
  placeholder: string
}

export function mapResourceOptions(
  options: any,
  label: ((value: any) => void) | string,
  valueKey: string
) {
  return _(options)
    .filter((option) => option)
    .map((option, id) => {
      const optionValue = option[valueKey] || id
      const optionLabel = (typeof label === 'function' && label(option)) || option[label as string]
      return {
        key: optionValue,
        value: optionValue,
        label: optionLabel,
      }
    })
    .sortBy(({ label }) => label && label.toLowerCase())
    .value()
}

export class ResourceDropDown extends React.Component<ResourceDropDownProps> {
  optionsRendererDefault = (options: any) => {
    const { label = 'name', valueKey = 'id' } = this.props
    return mapResourceOptions(options, label, valueKey)
  }

  renderOptions = (records: any) => {
    const { showPlaceholder, optionsRenderer, placeholder } = this.props
    const options = (optionsRenderer || this.optionsRendererDefault)(records) as any
    if (!options || !showPlaceholder) {
      return options
    }

    return [
      {
        key: 0,
        value: null,
        label: placeholder,
      },
      ...options,
    ]
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const {
      resource: { ready, error, payload },
      valueKey,
      onRefresh,
      ...rest
    } = this.props

    function handleRefresh() {
      if (onRefresh) {
        onRefresh()
      } else {
        window.location.reload()
      }
    }

    if (error) {
      return (
        <React.Fragment>
          <SelectDropDown {...rest} disabled placeholder="" />
          <span className="form-control-feedback" style={{ pointerEvents: 'initial', right: 90 }}>
            <button type="button" className="btn btn-link" onClick={handleRefresh}>
              <i className="fa fa-refresh" /> Refresh
            </button>
          </span>
        </React.Fragment>
      )
    }

    if (!ready || !payload || !payload.data) {
      return <SelectDropDown {...rest} disabled isLoading />
    }

    return <SelectDropDown options={this.renderOptions(payload.data)} searchable {...rest} />
  }
}

export const createProps = (props: any) => {
  if (props.resource && props.resource.error) {
    return {
      meta: {
        ...props.meta,
        touched: true,
        error: props.resource.error,
      },
    }
  }
}

export default function withResource(resource: any) {
  const mapStateToProps = (state: any) => ({
    resource: resource(state),
    valueKey: resource.idAttribute,
  })

  const Connected = connect(mapStateToProps)(ResourceDropDown) as any
  Connected.Field = compose(
    connect(mapStateToProps),
    flattenProp('input'),
    withProps(createProps),
    withFeedback
  )(ResourceDropDown as any)

  return Connected
}
